import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/AnimatedCounter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/BankInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BankTabItem"] */ "/vercel/path0/components/BankTabItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Copy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/DoughnutChart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Pagination"] */ "/vercel/path0/components/Pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Progress"] */ "/vercel/path0/components/ui/progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
